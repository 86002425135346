import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { initClient } from 'vuejs-oauth2-discord';

createApp(App).use(initClient, {
    host: 'aaronlem.ovh',
    port: 30,
    ssl: true,
    withCredentials: true,
    path: '/discordauth',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://aaronlem.ovh/',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
    }
}).use(router).mount('#app')