<template>
  <div id="aaron-logtool">
    <div id="logtool-head">
      <div id="importer">
        <p>
          Cet outil vous permet de voir vos logs joueurs avec les couleurs.
          Vous pouvez CTRL+F pour faire une recherche.
        </p>
        <h4>Indications</h4>
        <ul>
          <li>
            <span>VOUS ETES EN COMBAT</span> - Aller aux moments où vous étiez
            en combat
          </li>
          <li><span>[ASSAUT]</span> - Informations relatives aux assauts</li>
          <li>
            <span>vient de lancer un missile</span> - Missile annoncé dans le
            chat
          </li>
          <li>
            <span>##MonPseudo</span> - Quand un joueur prononce une phrase
            dans le chat global
          </li>
          <li><span>Demande de téléportation acceptée</span></li>
          <li><span>vous demande s'il peut se téléporter vers vous</span></li>
          <li><span>[BOSEconomy]</span> - Plugin d'économie du serveur</li>
        </ul>
        <h3>Selectionnez un horaire</h3>
        <div>
          <p>
            Plus vous sélectionnez un horaire court, plus le temps de
            chargement est rapide
          </p>
          <p>
            Prendre les logs entre <input id="timeA" type="time" />
            <input id="dateA" type="date" /> au
            <input id="timeB" type="time" /><input id="dateB" type="date" />
          </p>
          <p>
            ⚠️Attention : pour que ça fonctionne vous devez être connecté sur
            les dates écrites
          </p>
          <h2>Importez vos logs</h2>
        </div>
      </div>
      <div class="logtool-tuto">
        <h1>Log-Tool</h1>
        <h2>🔧Outil pour regarder dans les logs joueurs 🧰</h2>
        <img id="logtool-gif" />
      </div>
    </div>
    <div id="logtool-content"></div>
  </div>
</template>

<script>
var loading = false;

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const data = event.target.result;
      if (data.startsWith("data:application/octet-stream;base64,")) {
        resolve(data.slice(37));
      } else reject("");
    });

    reader.addEventListener("progress", (event) => {
      if (event.loaded && event.total) {
        const percent = (event.loaded / event.total) * 100;
        console.log(`Progress: ${Math.round(percent)}`);
      }
    });
    reader.readAsDataURL(file);
  });
}

class TimeBar {
  constructor() {
    this.paliers = [
      15, 30, 60, 120, 240, 480, 960, 1440, 2880, 5760, 11520, 23040, 43200,
      86400, 172800, 345600, 518400, 1036800, 2073600, 4147200,
    ];
    var time = 0;
    this.time = time;
    this.ta = document.getElementById("timeA");
    this.tb = document.getElementById("timeB");
    this.da = document.getElementById("dateA");
    this.db = document.getElementById("dateB");

    const speedbar = document.createElement("div");
    this.speedbar = speedbar;

    this.minBtn = document.createElement("button");
    this.minBtn.textContent = "15min";
    this.minBtn.addEventListener("click", (e) => {
      this.setDate(this.da, this.ta, -1, true);
    });

    this.btnVitesse = document.createElement("button");
    this.btnVitesse.textContent = "x2";
    this.btnVitesse.addEventListener("click", (e) => this.fois2());

    this.btnRalentir = document.createElement("button");
    this.btnRalentir.textContent = "/2";
    this.btnRalentir.addEventListener("click", (e) => this.diviser2());

    this.avancer = document.createElement("button");
    this.avancer.textContent = "=>";
    this.avancer.addEventListener("click", (e) => {
      this.setDate(this.da, this.ta, 1, this.da.value == "");
      this.setDate(this.db, this.tb, 1, this.db.value == "");
    });

    this.reculer = document.createElement("button");
    this.reculer.textContent = "<=";
    this.reculer.addEventListener("click", (e) => {
      this.setDate(this.da, this.ta, -1, this.da.value == "");
      this.setDate(this.db, this.tb, -1, this.db.value == "");
    });

    speedbar.appendChild(this.reculer);
    speedbar.appendChild(this.btnRalentir);
    speedbar.appendChild(this.minBtn);
    speedbar.appendChild(this.btnVitesse);
    speedbar.appendChild(this.avancer);
  }

  stringTemp() {
    const t = this.paliers[this.time];
    if (t < 60) return parseInt(t) + "min";
    else if (t < 1440) return parseInt(t / 60) + "h";
    else if (t < 43200) return parseInt(t / 1440) + "j";
    else if (t < 518400) return parseInt(t / 43200) + "mois";
    else return parseFloat(t / 518400) + "ans";
  }

  fois2() {
    if (this.time + 1 < this.paliers.length) {
      this.time++;
      this.minBtn.textContent = this.stringTemp();
    }
  }

  diviser2() {
    if (this.time > 0) {
      this.time--;
      this.minBtn.textContent = this.stringTemp();
    }
  }

  setDate(date, time, multiplier, n) {
    const ref = n
        ? Date.now()
        : new Date(date.value + "T" + time.value).getTime();
    const now = new Date(ref + multiplier * 60000 * this.paliers[this.time]);
    const mouth = now.getMonth() + 1;
    const day = now.getDate();
    const minutes = now.getMinutes();
    const hours = now.getHours();
    (date.value = `${now.getFullYear()}-${(mouth < 9 ? "0" : "") + mouth}-${
        (day < 9 ? "0" : "") + day
    }`),
        (time.value = `${(hours < 9 ? "0" : "") + hours}:${
            (minutes < 9 ? "0" : "") + minutes
        }`);
  }
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function rechercheDich(file, time, index = 0) {
  const length = file.length;
  let char = parseInt(length / 2);
  while (
      char < length - 4 &&
      file[char] + file[char + 1] + file[char + 2] + file[char + 3] != "<br>"
      ) {
    char++;
  }
  char += 4;
  const timeCode = file.substring(char, char + 16);
  if (time === timeCode || char >= length - 4) return index + char;
  else if (timeCode < time)
    return rechercheDich(file.substring(char, file.length), time, index + char);
  else return rechercheDich(file.substring(0, char), time, index);
}

class LogTool {
  /**
   * Initialise le navigateur sur la page home
   */
  constructor() {
    //Initalisation
    const { speedbar, da, db, ta, tb } = new TimeBar();
    this.content = document.getElementById("logtool-content");
    this.logtool = document.getElementById("aaron-logtool");

    const buttons = document.createElement("div");
    const tuto = document.createElement("div");

    const fileSelector = document.getElementById("importer");
    const fileSelectorIllustration = document.getElementById("logtool-gif");

    fileSelectorIllustration.setAttribute(
        "src",
        "https://i.imgur.com/aAS5RWb.gif"
    );

    const fileTitle = document.createElement("h2");
    fileTitle.textContent = "Importez vos logs";

    const fileInstruction = document.createElement("h3");
    fileInstruction.textContent =
        "Selectionnez votre output-client.log dans %appdata%/.ng/";

    const fileTarget = document.createElement("input");
    fileTarget.setAttribute("type", "file");
    fileTarget.textContent = "Importer";
    fileTarget.addEventListener("change", async (event) => {
      /* Importation en cours*/
      /* Message d'importation */
      fileInstruction.textContent = "Importation en cours...";
      const fileList = event.target.files;
      const file = await readFile(fileList[0]);
      fileInstruction.textContent =
          "📖Fichier chargé, en attente de lancement.";
      const a = atob(file).replace(/\n/gm, "§r<br>");
      this.content.innerHTML = "";

      function findConnection() {
        const indexes = new Array();
        const end = new Array();

        const saut = 10000;

        let ia = 0;
        let ib = saut;
        let n = 0;
        while (ia < a.length) {
          n++;
          const sub = a.substring(ia, ib);
          const index = sub.indexOf(" [CLIENT] [INFO] Connecting to ");
          // const indexEnd = sub.indexOf("[CLIENT] [INFO] Stopping!");
          // if (indexEnd != -1) end.push(indexEnd)
          if (index == -1) {
            ia += saut;
            ib += saut;
          } else {
            ia += index + 1;
            ib += index + 1 + saut;
            const date = sub.substring(index - 19, index);
            indexes.push({
              index: ia + index,
              time: date.substring(11, 19),
              date: date.substring(0, 10),
            });
          }
        }
        const div = document.createElement("div");
        div.id = "connections";
        for (let i = indexes.length - 1; i > 0; i--) {
          const index = indexes[i];
          const line = document.createElement("p");
          line.textContent = index.date + " " + index.time;
          line.addEventListener("click", (e) => {
            da.value = index.date;
            ta.value = index.time;
            if (i == indexes.length - 1) {
              db.value = "";
              tb.value = "";
            } else {
              db.value = indexes[i + 1].date;
              tb.value = indexes[i + 1].time;
            }
          });
          div.appendChild(line);
        }
        return div;
      }

      const connections = findConnection();
      //Changer l'image
      fileSelectorIllustration.setAttribute(
          "src",
          "https://i.imgur.com/D8aXyqI.gif"
      );

      function getLimits() {
        const ia =
            da.value == "" || ta.value == ""
                ? 0
                : rechercheDich(a, `${da.value} ${ta.value}`);
        const ib =
            db.value == "" || tb.value == ""
                ? a.length
                : rechercheDich(a, `${db.value} ${tb.value}`);
        return { ia, ib };
      }

      const horairesBtn = document.createElement("button");
      horairesBtn.textContent = "⏰ Vérifier les horaires";

      const startBtn = document.createElement("button");
      startBtn.textContent = "⏯️ Lancer";

      /* Vérification des horaires */
      horairesBtn.addEventListener("click", (e) => {
        const { ia, ib } = getLimits();
        //Message de validation(ou pas)
        if (ia == ib)
          fileInstruction.textContent =
              "❌ Vous n'étiez pas connecté sur l'intervalle";
        else if (ia >= ib)
          fileInstruction.textContent =
              "❌ Horaire invalide, l'horaire de début est après l'horaire de fin";
        else
          fileInstruction.textContent =
              ia == -1 || ib == -1
                  ? "❌ Horaire non trouvé"
                  : "✅ Horaire trouvé avec " +
                  (ib - ia) +
                  " caractères, en attente de lancement.✅";
      });
      //Connecting to .*.nationsglory.fr/
      //
      /* Afficher les logs */
      startBtn.addEventListener("click", (e) => {
        const { ia, ib } = getLimits();
        if (Math.abs(ib - ia) > 1000000)
          return (fileInstruction.textContent =
              "❌ Protection anti-lag " +
              parseInt((ib - ia) / 1000000) +
              "Millions de caractères.");

        /**
         *
         * @param {String} log Fichier de log
         * @returns {String} logs en html avec les bonnes couleurs
         */
        function replaceColors(log) {
          let res = "";
          let max = log.length;
          let ncouches = 0;

          function convertColor(symbol) {
            const c = ncouches;
            if (symbol == "r") {
              //Reset, cas "de fin"
              ncouches = 0;
              return "</span>".repeat(c);
            } else ncouches++;
            switch (symbol) {
              case "0":
                return '<span style="color: black">';
              case "1":
                return '<span style="color: darkblue">';
              case "2":
                return '<span style="color: darkgreen">';
              case "3":
                return '<span style="color: darkaqua">';
              case "4":
                return '<span style="color: darkred">';
              case "5":
                return '<span style="color: purple">';
              case "6":
                return '<span style="color: gold">';
              case "7":
                return '<span style="color: gray">';
              case "8":
                return '<span style="color: darkgrey">';
              case "9":
                return '<span style="color: indigo">';
              case "a":
                return '<span style="color: #7FFF00">';
              case "b":
                return '<span style="color: aqua">';
              case "c":
                return '<span style="color: red">';
              case "d":
                return '<span style="color: pink">';
              case "e":
                return '<span style="color: yellow">';
              case "f":
                return '<span style="color: white">';
              case "l":
                return '<span style="font-weight: bold">';
              case "o":
                return '<span style="font-style: italic">';
              case "n":
                return '<span style="text-decoration: underline">';
              case "m":
                return '<span style="text-decoration: line-through">';
              default:
                return "";
            }
          }

          /* Look les couleurs */
          for (let i = 0; i < max; i++) {
            if (log[i] == "§") {
              const spans = convertColor(log[i + 1]); //Ajoute un span selon la couleur
              i += 1;
              if (spans.length != 0) res += spans;
            } else res += log[i];
          }
          return res;
        }

        this.content.innerHTML =
            ia == ib || ia == -1 || ib == -1
                ? replaceColors(a)
                : replaceColors(a.slice(ia, ib));
        fileInstruction.textContent =
            "Sequence chargée ! Choisissez votre session :    ";
      });

      buttons.appendChild(horairesBtn);
      buttons.appendChild(startBtn);
      fileSelector.appendChild(connections);
    });
    buttons.appendChild(fileTarget);

    fileSelector.appendChild(fileTitle);
    fileSelector.appendChild(buttons);
    fileSelector.appendChild(speedbar);
    fileSelector.appendChild(fileInstruction);

    tuto.id = "logtool-tuto";

    tuto.innerHTML = ""; //Clear
  }
}

// const logtool = new LogTool();
console.log(this);
</script>

<style scoped>
#aaron-logtool {
  margin: auto;
  max-width: 90%;
  display: block;
  font-family: "gravity";
  color: white;
  word-wrap: break-word;
}

#logtool-content {
  background-color: black;
  padding: 10px;
  border-radius: 20px;
}

#logtool-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button {
  padding: 10px;
}

#logtool-gif {
  margin-left: 30px;
}

input[type="file"] {
  width: 100%;
  margin-bottom: 10px;
}

h1,
h2 {
  text-align: center;
}

h1 {
  font-size: 4em;
}

div#connections {
  max-height: 200px;
  overflow: scroll;
  padding-left: 5px;
}

div#connections p {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

div#connections p:hover {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  #logtool-head {
    flex-direction: column-reverse;
  }

  #logtool-gif {
    display: none;
  }
}
</style>