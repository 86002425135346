<template>
  <div>
    <h1>Recherchez tout ce qu'il vous faut</h1>
    <h3>Pays, joueur, serveur...</h3>
    <input
      type="text"
      @input="search"
      v-model="query"
      placeholder="pseudo, nom.."
    />
  </div>
  <div id="box_results">
    <h4>{{ results.length }} résultats trouvés</h4>
    <div id="search_results">
      <div
        v-for="result of results"
        :key="result.name"
        class="result"
        :style="card(result.color)"
      >
        <router-link :to="pathOf(result)" class="cardContent">
          <div>
            <img :src="result.icon" />
          </div>
          <div class="result_text">
            <p class="name">{{ result.name }}</p>
            <p class="type">
              {{ result.type }}
              <span v-if="result.type">{{ result.color }}</span>
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import palette from "../service/palette";
import getters from "../service/getters";

export default {
  name: "login",
  async mounted() {
    this.query = this.$route.query.search;
    if (this.query) this.search();
  },
  methods: {
    search() {
      this.lastTap = Date.now();
      console;
      setTimeout(() => {
        if (
          this.lastTap - Date.now() < 490 &&
          this.query.length >= 3 &&
          this.query !== this.lastSearch
        ) {
          this.lastSearch = this.query;
          getters.search(this.query).then((data) => {
            this.results = data;
          });
        }
      }, 500);
    },
    pathOf(result) {
      switch (result.type) {
        case "Joueur":
          return "/player?pseudo=" + result.name;
        case "Pays":
          return (
            "/country?name=" +
            result.name.toLowerCase() +
            "&color=" +
            result.color
          );
        case "Serveur":
          return "/server?name=" + result.name.toLowerCase();
        default:
          return "/navigator";
      }
    },
    card(key) {
      const color = palette.servers(key);
      return {
        "background-color": color
          ? `rgba(${color.r}, ${color.g}, ${color.b}, 0.4)`
          : "",
        cursor: "pointer",
        padding: "10px",
        margin: "10px",
        "border-radius": "20px",
        width: "45%",
        display: "flex",
        transition: "all 300ms ease-in-out",
      };
    },
  },
  data: () => {
    return {
      query: "",
      results: [],
      lastTap: 0,
      lastSearch: "",
    };
  },
};
</script>


<style scoped>
h1,
h3 {
  color: var(--headline);
  text-align: center;
  font-family: gravity, serif;
}
h3 {
  font-size: 1.5em;
}
input {
  font-family: gravity, serif;
  font-weight: bold;
  outline: none;
  font-size: 2em;
  width: 60%;
  padding: 20px;
  display: block;
  margin: auto;

  border-radius: 15px;
  border: none;
}
div#box_results {
  width: 70%;
  margin: auto;
}
div#search_results {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
div#search_results div.result:hover {
  background-color: var(--footer-color);
  transform: translateX(5px);
  box-shadow: 3px 4px 10px;
}
div#search_results div img {
  max-width: 125px;
  display: block;
}
div#search_results div img {
  max-width: 125px;
  display: block;
}
div#search_results div div.result_text {
  padding-left: 15px;
  color: var(--btn-color);
}
div#search_results div p.name {
  margin-top: 0;
  font-size: 1.8em;
  color: var(--headline);
  font-weight: bold;
}
@media only screen and (max-width: 900px) {
  div#search_results div.result {
    width: 95%;
  }
}
a {
  display: block;
}
.cardContent {
  display: flex;
}
</style>