<template>
  <SearchBar />
</template>
<script>
import SearchBar from "../components/SearchBar.vue";

export default {
  components: { SearchBar },
  setup() {
  },
};
</script>

<style scoped>
body,
#login_popup {
  margin: auto;
}
</style>