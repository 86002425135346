<template>
  <div class="progress-bar" ref="progressbar">
    <div class="progress-bar__content" ref="progressbar_content"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: Number,
  },
  mounted() {
    if (this.$props.value <= 0) return;

    const size = this.$props.value * this.$refs.progressbar.offsetWidth;
    this.$refs.progressbar_content.style.width = size.toFixed(2) + "px";
  }
}
</script>

<style scoped>
.progress-bar {
  background-color: var(--paragraph);
  width: 100%;
  height: 5px;

  border-radius: 25px;
}

.progress-bar__content {
  background-color: var(--secondary);
  height: 5px;
  width: 0;

  border-radius: 25px;
}

</style>