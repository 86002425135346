<template>
  <footer class="footer">
    <div>
      <h2>Aaron le mercenaire - rue horseilles 4 5300 Andenne</h2>
      <hr class="footer__line" />
      <h2>Site développé par :</h2>
      <p class="footer__developers">Jelybils#7855 | BigChef#8943 | XDemon🌴#0947 | Pioupia#3611</p>
      <hr class="footer__line-45" />
      <h2>
        <RouterLink to="/cgu" class="footer__link">
          CGU - Aaron
        </RouterLink>
      </h2>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  border-top: var(--paragraph) solid 1px;
  background-color: var(--footer-color);
  color: var(--headline);
  text-align: center;
  margin-top: 5em;
  padding: 20px;
}

.footer__developers {
  color: var(--headline);
  text-decoration: none;
  font-size: 20px;
}

.footer__line {
  margin-left: 39%;
  margin-right: 39%;
  color: var(--paragraph);
}

.footer__line-45 {
  margin-left: 45%;
  margin-right: 45%;
  color: var(--paragraph);
}

.footer__link {
  color: var(--headline);
}
</style>
