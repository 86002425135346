<template>
  <header>
    <NavBar />
  </header>
  <main>
    <div v-if="player">
      <div id="player_infos">
        <div class="lang">
          <span v-if="player.lang == 'fr'">🇫🇷</span>
          <span v-else-if="player.lang == 'us'">🇺🇸</span>
        </div>
        <div v-if="player.prime"><img src="../assets/prime.png" /></div>
        <div class="title">
          <h1>
            <img
              id="face"
              :src="
                'https://skins.nationsglory.fr/face/' + player.pseudo + '/8'
              "
            />{{ player.pseudo }}
          </h1>
          <h2>{{ player.description }}</h2>
        </div>
        <div class="scrappingInfos">
          <span v-if="player.scrappedAt != 0"
            >Informations mises à jour {{ timeStampAt() }}</span
          >
          • <span>Il y a {{ sinceTs(player.scrappedAt / 1000) }}</span>
        </div>
      </div>

      <div class="cards">
        <div v-for="server in player.colorData" :key="server.color.color">
          <router-link
            :to="
              server.country
                ? '/country?name=' +
                  server.country.name +
                  '&color=' +
                  server.color.color
                : ''
            "
            :style="card(server.color.color)"
            class="cardContent"
          >
            <div class="descServer">
              <h3>
                {{
                  server.color.color.charAt(0).toUpperCase() +
                  server.color.color.substring(1)
                }}
              </h3>
              <p :style="styleOfGrade(server.grade)">{{ server.grade }}</p>
              <p>
                Connecté il y a
                {{ sinceTs(Date.now() / 1000 - server.last_connection) }}
              </p>
              <p>A joué {{ sinceTs(server.playTime) }}</p>
              <p v-if="server.power">
                {{ server.power }}/{{ server.powerMax }} power
              </p>
            </div>
            <div v-if="server.country">
              <img :src="'data:image/png;base64,' + server.country.flag" />
              <h4 class="descCountry">
                {{
                  server.role.charAt(0).toUpperCase() + server.role.substring(1)
                }}
                dans {{ server.country.caseName }}
              </h4>
            </div>
            <div v-else><h4 class="descCountry">Dans aucun pays</h4></div>
          </router-link>
        </div>
      </div>
    </div>
  </main>
  <footer>
    <Footer />
  </footer>
</template>
<script>
import getters from "../service/getters";
import palette from "../service/palette";
//import component
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
export default {
  components: { Footer, NavBar },
  name: "login",
  async mounted() {
    this.pseudo = this.$route.query.pseudo;
    if (this.pseudo) this.get();
    else this.$router.push("../navigator");
  },
  methods: {
    mont(pseudo) {
      this.pseudo = pseudo;
      this.$route.query.pseudo = pseudo;
      this.get();
      this.player = null;
    },
    get() {
      getters.getPlayer(this.pseudo).then((player) => {
        this.player = player;
      });
    },
    countryUrl(country) {
      return "/country?name=" + country.name + "&color=" + this.color;
    },
    timeStampAt() {
      return new Date(this.player.scrappedAt * 1000).toDateString();
    },
    sinceTs(ts) {
      return getters.sinceTimeStamp(ts);
    },
    styleOfGrade(grade) {
      const rgb = palette.grade(grade);
      return rgb
        ? {
            color: "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ")",
          }
        : {};
    },
    card(server) {
      const color = palette.servers(server);
      return {
        "background-color": color
          ? `rgba(${color.r}, ${color.g}, ${color.b}, 0.4)`
          : "",
        cursor: "pointer",
        padding: "10px",
        margin: "10px",
        "border-radius": "20px",
        width: "45%",
        display: "flex",
        transition: "all 300ms ease-in-out",
        display: "block",
        color: "white",
        width: "60%",
        margin: "auto",
        cursor: "pointer",
      };
    },
  },
  data: () => {
    return {
      pseudo: "",
      player: null,
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 4em;
  text-align: center;
  color: var(--headline);
}
#face {
  transform: translateY(14px);
  margin-right: 5px;
}
h2 {
  font-size: 2em;
  text-align: center;
  color: var(--paragraph);
}
.scrappingInfos {
  text-align: center;
}
#player_infos {
  margin-bottom: 2em;
}
.cardContent:hover {
  background-color: var(--footer-color);
  transform: translateX(5px);
  box-shadow: 3px 4px 10px;
}
.cardContent div {
  display: flex;
}
.descServer {
  justify-content: space-between;
}
.descCountry {
  margin-left: 5px;
  font-size: 1.5em;
}
.cards {
  margin: auto;
}
.cards div {
  margin-bottom: 1em;
}
</style>