<template>
  <div class="PageNotFound">
    <h1>404 : Page not found</h1>
  </div>
</template>

<style scoped>
h1 {
  font-family: atmos, sans-serif;
  text-align: center;
  color: white;
  margin-top: 100px;
}
</style>
<script>
//use components for template
export default {
  name: "App"
};
</script>