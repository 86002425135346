/**
 * Permet de parser le contenu d'un token JWT
 * @param {string} jwt
 * @return {object}
 */
function parse(jwt) {
    const object = {};
    if (typeof jwt !== 'string') return object;

    const data = jwt.split('.');
    if (data.length !== 3) return object;

    try {
        const parsedData = JSON.parse(atob(data[1]));
        if (typeof parsedData.exp !== "number") return object;

        // Si jamais la date d'expiration moins 20 secondes est inférieure à la date
        // actuelle, alors on ne renvoie aucun résultat, sinon, on renvoie les données.
        if ((parsedData - 20)*1e3 < Date.now()) return object;

        return parsedData;
    } catch(e) {
        return object;
    }
}

module.exports = {
    parse
}