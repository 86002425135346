import { createRouter, createWebHistory } from 'vue-router'
import PageNotFound from '../views/PageNotFound.vue'
import Home from '../views/Home.vue'
import Commandes from '../views/Commandes.vue'
import Equipe from '../views/Equipe.vue'
import CGU from '../views/CGU.vue'
import LogTool from '../views/logtool.vue'
import Navigator from '../views/Navigator.vue'
import Country from '../views/Country.vue'
import Player from '../views/Player.vue'
import Pillages from '../views/Pillages.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/commandes',
        name: 'commandes',
        component: Commandes
    },
    {
        path: '/equipe',
        name: 'equipe',
        component: Equipe
    },
    {
        path: '/cgu',
        name: 'cgu',
        component: CGU
    },
    {
        path: '/navigator',
        name: 'navigator',
        component: Navigator
    },
    {
        path: '/country',
        name: 'country',
        component: Country
    },
    {
        path: '/player',
        name: "player",
        component: Player
    },
    {
        path: "/log-tool",
        name: "logtool",
        component: LogTool
    },
    {
        path: "/pillage",
        name: "pillages",
        component: Pillages
    },
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (savedPosition) return savedPosition;

        return {
            el: '.topnav',
            behavior: 'smooth'
        }
    }
})


export default router