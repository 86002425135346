<template>
  <NavBar />
  <main>
    <router-view />
  </main>
  <Footer />
</template>

<script>
//import component
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

//use components for template
export default {
  name: "App",
  components: { NavBar, Footer },
};
</script>

<style>
@font-face {
  font-family: "gravity";
  src: local("gravity"), url(./fonts/Gravity-Light.otf);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "gravity";
  src: local("gravity"), url(./fonts/Gravity-Book.otf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "gravity";
  src: local("gravity"), url(./fonts/Gravity-Regular.otf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "gravity";
  src: local("gravity"), url(./fonts/Gravity-Bold.otf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "atmos";
  src: local("atmos"), url(./fonts/atmos.ttf);
  font-display: swap;
}

:root {
  --bg-color: #0f0e17;
  --bg-color-bright: #222130;
  --footer-color: #2e2f3e;
  --footer-color-bright: #333545;
  --headline: #fffffe;
  --paragraph: #a7a9be;
  --secondary: #ff6f5c;
  --btn-color: #ff8906;
  --btn-color-hover: #ed8005;
  --link-color: #f297b7;
  --hover-link-color: #784100;
  --discord-background: #7289da;
  --green-color: #4AFF88;
}

#app {
  font-family: gravity, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--headline);
  scroll-behavior: smooth;
}

main {
  height: calc(100% - 90px);
  padding: 2em;
}

#topnav_menu a.router-link-active {
  color: var(--headline) !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}
</style>
