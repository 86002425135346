<template>
  <h1 class="title">Aaron Le Mercenaire</h1>
  <p class="description">
    Un bot certifié par NationsGlory, reliant NG à discord ! <br />
    Trouve tes pillages, disbands, et pleins d'autres fonctionnalités !
  </p>

  <div class="buttons-container">
    <a class="buttons-container_discord"
       target="_blank"
       referrerpolicy="no-referrer"
       href="https://discord.com/api/oauth2/authorize?client_id=816374380456050739&permissions=11006233681&scope=bot%20applications.commands">
      Ajouter à Discord
    </a>

    <router-link class="buttons-container_commands" to="/commandes">
      Voir les fonctionnalités
    </router-link>
  </div>
</template>

<style scoped>
.title {
  padding: 0 15vw;
  margin-top: 0;

  text-align: center;
  font-size: 100px;

  word-break: break-word;
}

.description {
  text-align: center;
  font-size: 20px;
  word-break: break-word;

  line-height: 1.5em;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.buttons-container a {
  height: max-content;

  margin: 10px;
  padding: .5em 1.3em;

  border-radius: 10px;

  font-size: 1.5em;
  font-weight: 500;
  text-decoration: none;
  color: var(--bg-color);

  transition: background-color 300ms ease-in-out;
}

.buttons-container_discord {
  background-color: var(--btn-color);

  box-shadow: 1.2px 1.2px 2.2px -10px rgba(130, 130, 130, 0.09),
  3px 3px 5.3px -10px rgba(130, 130, 130, 0.068),
  5.6px 5.6px 10px -10px rgba(130, 130, 130, 0.061),
  10.1px 10.1px 17.9px -10px rgba(130, 130, 130, 0.056),
  18.8px 18.8px 33.4px -10px rgba(130, 130, 130, 0.05),
  45px 45px 80px -10px rgba(130, 130, 130, 0.039);
}

.buttons-container_discord:hover {
  background-color: var(--btn-color-hover);
}

.buttons-container_commands {
  background-color: var(--paragraph);
  box-shadow: 1.2px 1.2px 2.2px -10px rgba(130, 130, 130, 0.014),
  3px 3px 5.3px -10px rgba(130, 130, 130, 0.02),
  5.6px 5.6px 10px -10px rgba(130, 130, 130, 0.025),
  10.1px 10.1px 17.9px -10px rgba(130, 130, 130, 0.03),
  18.8px 18.8px 33.4px -10px rgba(130, 130, 130, 0.036),
  45px 45px 80px -10px rgba(130, 130, 130, 0.05);
}

@media only screen and (max-width: 900px) {
  #responsive_button2 {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 1900px) {
  body {
    background-position-x: 380px;
  }
}

@media only screen and (max-width: 1820px) {
  body {
    background-position-x: 320px;
  }
}

@media only screen and (max-width: 1750px) {
  body {
    background-position-x: 250px;
  }
}

@media only screen and (max-width: 1730px) {
  body {
    background-position-x: 180px;
  }
}

@media only screen and (max-width: 1700px) {
  body {
    background-position-x: 140px;
  }
}

@media only screen and (max-width: 1680px) {
  body {
    background-size: 95%;
    background-position-x: 50px;
  }
}

@media only screen and (max-width: 1500px) {
  body {
    background-size: 75%;
    background-position-x: 300px;
    background-position-y: 190px;
  }
}

@media only screen and (max-width: 1300px) {
  body {
    background-size: 75%;
    background-position-x: 250px;
    background-position-y: 190px;
  }
}

@media only screen and (max-width: 1100px) {
  body {
    background-image: none;
  }
}
</style>

<script>
//use components for template
export default {
  name: "App"
};
</script>
