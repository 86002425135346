<template>
  <div v-if="working" @keydown.left="decCount()" @keydown.right="upCount()">
    <h1>Il vous reste {{ pillages.length - count }} pays à piller</h1>
    <div id="progressBar"><ProgressBar :value="count / pillages.length" /></div>
    <div class="menu">
      <button v-if="count > 0" class="return count" @click="decCount()">
        🡰
      </button>
      <button class="change" @click="clear()">Changer de serveur</button>
      <button class="continue count" @click="upCount()">🡲</button>
    </div>
    <div>
      <Country :country="pillages[count]" :color="server.color" />
    </div>
  </div>
  <div v-else-if="server">
    <h1>
      Vous êtes sur le
      <span>{{
        server.color.charAt(0).toUpperCase() + server.color.substring(1)
      }}</span>
    </h1>
    <div v-if="loaded" @keydown.enter="launch()">
      <h3>Il y a {{ pillages.length }} en sous power</h3>
      <div id="pillages">
        <div v-for="pillage in pillages" :key="pillage.name">
          <img :src="pillage.flag" />
          <h4>{{ pillage.caseName }}</h4>
          <p>{{ pillage.pillage }} claims</p>
        </div>
      </div>
      <div id="startButton">
        <button class="change" @click="clear()">Changer de serveur</button>
        <button id="letsgo" @click="launch()">C'est parti !</button>
      </div>
    </div>
    <h3 v-else>Récupération des pillages en cours...</h3>
  </div>
  <div v-else>
    <h1>Faites vos pillages</h1>
    <h3>Quel est votre serveur ?</h3>
    <div id="form" v-if="servers.length > 0">
      <button
        v-for="server in servers"
        :key="server.color"
        :style="serverColorBtn(server.color)"
        v-on:click="selectServer(server)"
      >
        {{ server.color.charAt(0).toUpperCase() + server.color.substring(1) }}
      </button>
    </div>
    <div v-else>Chargement des serveurs...</div>
  </div>
</template>

<script>
import palette from "../service/palette";
import getters from "../service/getters";

import ProgressBar from "@/components/ProgressBar.vue";
import Country from "../components/Country.vue";

export default {
  name: "pillages",
  components: { Country, ProgressBar },
  async mounted() {
    this.server = this.$route.query.server;
    if (this.server) this.search();
    else {
      getters.serverColors().then((servercolors) => {
        this.servers = servercolors;
      });
    }
  },
  methods: {
    serverColorBtn(color) {
      const rgb = palette.servers(color);
      return {
        "background-color": "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ")",
        padding: "30px",
        "border-radius": "30px",
        "font-family": "gravity",
        "font-weight": 500,
        color: "white",
        "font-size": "2em",
        border: "none",
        margin: "0.8em",
        cursor: "pointer",
      };
    },
    selectServer(serv) {
      this.server = serv;
      getters
        .getPillages(this.server._id)
        .then((lstPillages) => {
          if (!serv.bedrock)
            for (let i = 0; i < lstPillages.length; i++) {
              lstPillages[i].flag =
                "data:image/png;base64," + lstPillages[i].flag;
            }
          this.pillages = lstPillages;
          this.loaded = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    clear() {
      this.server = null;
      this.limit = null;
      this.serverName = null;
      this.pillages = null;
      this.loaded = false;

      this.count = 0;
      this.working = false;
    },
    launch() {
      this.working = true;
    },
    skip() {
      if (this.count < this.pillages.length) {
        this.count++;
      } else this.clear();
    },
    decCount() {
      if (this.count > 0) this.count--;
    },
    upCount() {
      if (this.count < this.pillages.length - 1) this.count++;
      else {
        this.working = false;
        this.count = 0;
      }
    },
  },
  data: () => {
    return {
      servers: [],
      serverName: "",
      server: null,
      limit: "",
      pillages: [],
      loaded: false,
      working: false,
      count: 0,
    };
  },
};
</script>


<style scoped>
h1,
h3 {
  color: var(--headline);
  text-align: center;
  font-family: gravity, serif;
}
h3 {
  font-size: 1.5em;
}
#progressBar {
  width: 30%;
  height: 1em;
  margin: auto;
}
#form {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}
#form button {
  transition: all 250ms ease-in-out;
}
#form button:hover {
  text-decoration: underline var(--btn-color);
}
#pillages {
  /* A supprimer, mettre les éléments en arrière plan avec des effets ce serait pas mal ^^ */
  display: flex;
  justify-content: space-between;
  width: 70%;
  flex-wrap: wrap;
  margin: auto;
}
#pillages div {
  display: flex;
  justify-content: space-between;
}
#startButton {
  margin: auto;
  margin-top: 150px;
  display: flex;
  justify-content: space-around;
  width: 40%;
}
#startButton button {
  font-size: 1.5em;
  padding: 20px;
  margin: auto;
  border: none;
  border-radius: 15px;
}
#letsgo {
  background-color: green;
  color: var(--headline);
  transition: all 200ms ease-in-out;
}
#letsgo:hover {
  color: green;
  background-color: var(--headline);
}
.menu {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: auto;
  margin-bottom: 1em;
}
button.count {
  padding: 0.5em;
  font-size: 2em;
  border-radius: 100%;
  background-color: var(--btn-color);
  border: none;
  transition: all 150ms ease-in-out;
}
button.count:hover {
  color: var(--headline);
  background-color: var(--btn-color-hover);
}
button.change {
  background: var(--footer-color);
  color: var(--headline);
  border: none;
  border-radius: 15px;
  padding: 1em;
  font-size: 1em;
  transition: all 200ms ease-in-out;
}
button.change:hover {
  background: var(--headline);
  color: var(--footer-color);
}
</style>