const aaron = require("aaron-api-client");
const { parse } = require("./JWTManager");

const local = window.localStorage;
let data = {
    token: local.getItem("token"),
    commands: null,
    search: new Map(),
    searchQueries: new Set()
}

const times = {
    year: 3600 * 24 * 365,
    mounth: 3600 * 24 * 30,
    day: 3600 * 24,
    hour: 3600,
    minutes: 60
}

if (!data.token || !parse(data.token)?.data) {
    aaron.init({
        email: "user@aaronlem.ovh",
        password: 'deijfhruzg7r(;',
        url: "aaronlem.ovh/api",
        protocol: "https"
    }).then(t => {
        if (typeof t !== "string") return false;

        data.token = t;
        local.setItem("token", t);
    }).catch(err => {
        console.log(err);
    });
} else {
    aaron.token = data.token;
}

try {
    const commands = JSON.parse(
        local.getItem("commands")
    );

    if ((commands?.exp * 1e3 || 0) > Date.now()) {
        data.commands = commands.data;
    }
} catch (e) { }

module.exports = {
    getCmds: async function () {
        if (data.commands) return data.commands;
        if (!data.token) return [];

        const commands = await aaron.send({
            method: "get",
            url: "/cmds"
        });

        data.commands = commands;
        local.setItem("commands", JSON.stringify({
            exp: Math.floor(Date.now() / 1e3) + (24 * 60 * 60), // un jour de cache
            data: commands
        }));

        return commands;
    },
    /**
     *
     * @param {string} email
     * @param {string} password
     */
    login: async function (email, password) {
        if (data.token && parse(data.token)?.data) return data.token;

        return aaron.init({
            email: email,
            password: password
        })
    },
    search: async function (search) {
        if (!data.token) return [];

        search = search.toLowerCase().trim();

        // données startsWith / égales
        const keys = data.searchQueries.keys();
        let value = keys.next();

        while (!value.done) {
            if (search.startsWith(value.value.toLowerCase())) {
                // ici, on sait qu'on peut chercher dans la maps les valeurs correspondantes.
                const result = [];

                data.search.forEach((value, key) => {
                    if (key.toLowerCase().startsWith(search)) {
                        value.name = key;
                        result.push(value);
                    }
                });

                return result;
            }

            value = keys.next();
        }

        const result = await aaron.send({
            url: '/startWith?search=' + search,
            method: 'get'
        });

        data.searchQueries.add(search);
        result.forEach(res => {
            const key = res.name;
            delete res.name;

            data.search.set(key, res);

            res.name = key;
        });

        return result;
    },
    getCountry(name, color) {
        return aaron.send({
            url: `/country?name=${name}&color=${color}`,
            method: 'get'
        })
    },
    getPlayer(pseudo) {
        return aaron.send({
            url: "/player?pseudo=" + pseudo,
            method: 'get'
        })
    },
    serverColors() {
        return aaron.send({
            url: "/serverColor?select=color bedrock",
            method: 'get'
        });
    },
    sinceTimeStamp(time) {

        const years = parseInt(Math.floor(time / times.year)) - 1;
        time %= times.year;

        const mounths = parseInt(Math.floor(time / times.mounth)) - 1;
        time %= times.mounth

        const days = parseInt(Math.floor(time / times.day)) - 1;
        time %= times.day;

        const hour = parseInt(Math.floor(time / times.hour)) - 1;
        time %= times.hour;

        const minutes = parseInt(Math.floor(time / times.minutes)) - 1;
        time %= times.minutes

        let txt = "";
        if (years > 0) txt += years + (years > 1 ? " années, " : "année, ");
        if (mounths > 0) txt += mounths + " mois, ";
        if (days > 0) txt += days + (days > 1 ? " jours, " : " jour, ");
        if (hour > 1) txt += hour + "." + minutes + (hour > 1 ? " heures" : " heure");
        else if (minutes > 1) txt += minutes + (minutes > 1 ? " minutes" : " minute")
        else txt += time + " secondes"
        return txt;
    },
    getPillages(colorId) {
        return aaron.send({
            url: "/pillage?colorId=" + colorId,
            method: 'get'
        });
    }
}