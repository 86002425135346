<template>
  <div v-if="country">
    <Country :country="country" :color="color" />
  </div>
  <div v-else id="attente">
    <h4>Chargement en cours...</h4>
  </div>
</template>

<script>
import getters from "../service/getters";
import palette from "../service/palette";
import Country from "../components/Country.vue";

export default {
  name: "login",
  components: { Country },
  async mounted() {
    this.name = this.$route.query.name;
    this.color = this.$route.query.color;
    if (this.name && this.color) this.get();
    else this.$router.push("../navigator");
  },
  methods: {
    mont(name) {
      this.name = name;
      this.$route.query.name = name;
      this.get();
      this.country = null;
    },
    get() {
      getters.getCountry(this.name, this.color).then((country) => {
        if (!country.flag.includes("https"))
          country.flag = "data:image/png;base64," + country.flag;
        this.country = country;
      });
    },
    getCoordsUrl(dim, worldname) {
      if (dim && dim[0]) {
        const claims = dim[0].claims;
        const x =
            claims[0][0] -
            parseInt(claims[0][0] - claims[claims.length / 2][0]) / 2,
          y = 0,
          z =
            claims[0][1] -
            parseInt(claims[0][1] - claims[claims.length / 2][1]) / 2,
          zoom =
            6 -
            parseInt(Math.abs(claims[0][0] - claims[claims.length / 2][0])) /
              200;
        return `https://${this.color}.nationsglory.fr/?worldname=${worldname}&mapname=flat&zoom=${zoom}&x=${x}&y=${y}&z=${z}&nopanel=true&hidechat=true&nocompass=true`;
      }
    },
    setFlag(flag) {
      return flag
        ? flag.includes("https")
          ? flag
          : "data:image/png;base64," + flag
        : "https://i.imgur.com/ZB0yLME.png";
    },
    overlay(type) {
      console.log(type, this.overlays[type]);
      this.overlays[type] = !this.overlays[type];
    },
    countryUrl(country) {
      return "/country?name=" + country.name + "&color=" + this.color;
    },
    sinceTs(ts) {
      return getters.sinceTimeStamp(ts);
    },
    styleOfGrade(grade) {
      const rgb = palette.grade(grade);
      return rgb
        ? {
            color: "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ")",
          }
        : {};
    },
  },
  data: () => {
    return {
      country: null,
      name: "",
      color: "",
      overlays: {
        players: false,
        allies: false,
        ennemies: false,
        colonies: false,
        notations: false,
        assauts: false,
      },
    };
  },
};
</script>


<style scoped>
.country {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.country__infos {
  width: 70%;
}

.country__infos__head {
  display: flex;
}

.country__infos__head .country__infos__head__flag {
  margin-right: 20px;
}

.country__infos__head-titles {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.country__infos__head-titles:nth-child(2) {
  color: var(--paragraph);
}

.country__infos__head__flag-img {
  border-radius: 5px;
}

.country__infos_statistics {
  display: flex;
  color: var(--headline);
}

.country__infos_statistics {
  margin-top: 2em;
  margin-bottom: 2em;

  display: flex;
  justify-content: space-evenly;
}

.country__infos_statistics__container-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;

  color: var(--paragraph);
}

.country__infos_statistics__container-value {
  text-align: center;
  color: var(--headline);

  font-weight: 800;

  margin-bottom: 0.3em;
}

.country__select-container {
  background-color: var(--footer-color);

  border-radius: 8px;
  border: var(--paragraph) solid 1px;

  margin: 2em 0;
  padding: 2em;
  cursor: pointer;
}

.country__select-container.inactive {
  filter: brightness(80%);
}

.inactive > .country__select-container__header {
  cursor: initial;
}

.country__select-container__header {
  display: flex;
}

.country__select-container__header-left {
  display: flex;
  align-items: center;

  height: max-content;
}

.country__select-container__header-right {
  height: max-content;
  margin-left: auto;
}

.number_box {
  margin: 0;
  padding: 0.2em 0.5em;
  background-color: var(--bg-color);
  border-radius: 8px;
}

.overlay_title {
  margin: 0 0 0 10px;
  font-size: 1.3em;
}

.players {
  width: 95%;

  margin: 2em auto 0;

  text-align: left;
  vertical-align: center;

  border-spacing: 5px 1rem;
  border-collapse: collapse;
}

.players thead {
  background-color: var(--bg-color-bright);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.players th {
  padding: 15px 15px 15px 8px;
  font-size: 0.835rem;
}

.players td {
  padding: 8px;
}

.players tbody tr:nth-child(even) {
  background-color: var(--footer-color-bright);
}

#country_infos {
  margin-right: 15px;
}

h1 {
  color: var(--headline);
}

.select {
  border: 1px var(--headline) ridge;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 2px;
  transition: all 300ms ease-in-out;
}

.select .overlay_btn {
  cursor: pointer;
  padding: 7px;
  transition: all 300ms ease-in-out;
}

.select .overlay_btn:hover {
  text-decoration: underline;
  box-shadow: 1px 1px 5px;
  background: var(--footer-color);
}

.select .overlay_btn button {
  background: none;
  color: var(--headline);
  font-size: 1em;
  padding: none;
  border: none;
}

.select .overlay_btn h4 {
  font-size: 1.5em;
}

.select .overlay_btn .overlay_title {
  color: var(--headline);
  margin-left: 10px;
}

iframe {
  width: 100%;
  height: 500px;
}

.important {
  color: var(--headline);
  font-weight: bold;
  font-size: 1.2em;
}

.link {
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.link a {
  display: block;
}

/* .link:hover {
  background-color: var(--footer-color);
  transform: translateX(5px);
  box-shadow: 3px 4px 10px;
} */

#attente h4 {
  font-size: 3em;
  text-align: center;
}

#dynmaps h4 {
  font-size: 2em;
  color: var(--headline);
}

a {
  cursor: pointer;
  color: white;
  display: block;
}

/* .gg icons */
.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}
</style>