<template>
    <!-- Box  -->
    <div class="box">
      <div class="boxborder">
        <img id="imgbox" src="../assets/GrosChef.webp" />
        <h2 id="boxcontains">
          BigChef <br />
          <div id="boxcont2">Responsable de la maintenance</div>
        </h2>
      </div>

      <div class="boxborder">
        <img id="imgbox" src="../assets/croux.webp" />
        <h2 id="boxcontains">
          Croux <br />
          <div id="boxcont2">Administrateur Bot Discord</div>
        </h2>
      </div>

      <div class="boxborder">
        <img id="imgbox" src="../assets/XDemon.webp" />
        <h2 id="boxcontains">
          XDemon <br />
          <div id="boxcont2">Developpeur API/Scrapping en pause</div>
        </h2>
      </div>

      <div class="boxborder">
        <img id="imgbox" src="../assets/solar.webp" />
        <h2 id="boxcontains">
          SolarDig14<br />
          <div id="boxcont2">Administrateur affaires administratives</div>
        </h2>
      </div>

      <div class="boxborder">
        <img id="imgbox" src="../assets/TeamRed.webp" />
        <h2 id="boxcontains">
          Pioupia<br />
          <div id="boxcont2">Developpeur web/Discord</div>
        </h2>
      </div>
    </div>
</template>

<script>
//use components for template
export default {
  name: "App"
};
</script>

<style scoped>
.box {
  flex-wrap: wrap;
  gap: 10px 5em;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
}

.boxborder {
  border: solid white 2px;
  border-radius: 20px;
  background-color: #383844;
  width: 20%;
  height: 20%;
}

#boxcontains {
  text-align: center;
  color: rgb(0, 0, 0);
  font-family: atmos, serif;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin: 0 0 -5px;
  border-radius: 20px;
}

#boxcont2 {
  font-size: 10px;
}

#imgbox {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1468px) {
  .boxborder {
    width: 18%;
    height: 18%;
  }

  #boxcontains {
    font-size: 15px;
  }

  #boxcont2 {
    font-size: 7px;
  }
}

@media only screen and (max-width: 1150px) {
  .boxborder {
    width: 25%;
    height: 25%;
  }
}

@media only screen and (max-width: 1000px) {
  .boxborder {
    width: 50%;
    height: 50%;
  }
}
</style>
