<template>
  <div v-if="country" class="country">
    <div class="country__infos">
      <div class="country__infos__head">
        <div class="country__infos__head__flag">
          <img class="country__infos__head__flag-img" :src="country.flag" />
        </div>
        <div>
          <h1 class="country__infos__head-titles">{{ country.caseName }}</h1>
          <h3 class="country__infos__head-titles">{{ country.description }}</h3>
        </div>
      </div>
      <div class="country__infos_statistics">
        <div class="country__infos_statistics__container">
          <h4 class="country__infos_statistics__container-title">Niveau</h4>
          <p class="country__infos_statistics__container-value">
            {{ country.level }}
          </p>
        </div>
        <div class="country__infos_statistics__container">
          <h4 class="country__infos_statistics__container-title">Power</h4>
          <p class="country__infos_statistics__container-value">
            {{ country.power }} / {{ country.powerMax }}
          </p>
          <ProgressBar :value="country.power / country.powerMax" />
        </div>
        <div class="country__infos_statistics__container">
          <h4 class="country__infos_statistics__container-title">Claims</h4>
          <p class="country__infos_statistics__container-value">
            {{ country.claims }}
          </p>
        </div>
        <div class="country__infos_statistics__container">
          <h4 class="country__infos_statistics__container-title">Status</h4>
          <p
            v-if="country.pillage < 0"
            class="country__infos_statistics__container-value"
          >
            <b>⚠️</b> Pays en sous power
          </p>
          <p v-else class="country__infos_statistics__container-value">
            Stable
          </p>
        </div>
      </div>
      <div @click="overlay('players')" class="country__select-container">
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <i class="gg-chevron-up" v-if="overlays.players"></i>
            <i class="gg-chevron-down" v-else></i>
            <h4 class="overlay_title">Joueurs</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.players.length }}
            </p>
          </div>
        </div>
        <table class="players" v-show="overlays.players">
          <thead>
            <tr>
              <th>Tete</th>
              <th>Pseudo</th>
              <th>Role</th>
              <th>Grade</th>
              <th>Power</th>
              <th>Temps de jeu</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="player in country.players"
              :key="player.player.pseudo"
              class="link"
            >
              <td>
                <router-link
                  :to="'/player?pseudo=' + player.player.pseudo"
                  class="stretched-link"
                >
                  <img
                    :src="
                      'https://skins.nationsglory.fr/face/' +
                      player.player.pseudo +
                      '/3d/3'
                    "
                  />
                </router-link>
              </td>
              <td class="important">
                <router-link
                  :to="'/player?pseudo=' + player.player.pseudo"
                  class="stretched-link"
                >
                  {{ player.player.pseudo }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/player?pseudo=' + player.player.pseudo"
                  class="stretched-link"
                >
                  {{ player.role }}
                </router-link>
              </td>
              <td :style="styleOfGrade(player.grade)">
                <router-link
                  :to="'/player?pseudo=' + player.player.pseudo"
                  class="stretched-link"
                >
                  {{ player.grade }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/player?pseudo=' + player.player.pseudo"
                  class="stretched-link"
                >
                  {{ player.power }}/{{ player.powerMax }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/player?pseudo=' + player.player.pseudo"
                  class="stretched-link"
                >
                  {{ player.playTime }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        @click="overlay('allies')"
        class="country__select-container"
        v-if="country.allies.length > 0"
      >
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <i class="gg-chevron-up" v-if="overlays.allies"></i>
            <i class="gg-chevron-down" v-else></i>
            <h4 class="overlay_title">Alliés</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.allies.length }}
            </p>
          </div>
        </div>
        <table v-show="overlays.allies" class="players">
          <thead>
            <tr>
              <th>Drapeau</th>
              <th>Nom</th>
              <th>Joueurs</th>
              <th>MMR</th>
              <th>Alliés</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ally in country.allies"
              :key="ally.name"
              class="link"
              @click="mont(ally.name)"
            >
              <td>
                <router-link
                  :to="'/country?name=' + ally.name + '&color=' + color"
                >
                  <img :src="setFlag(ally.flag)" style="width: 100px" />
                </router-link>
              </td>
              <td class="important">
                <router-link
                  :to="'/country?name=' + ally.name + '&color=' + color"
                >
                  {{ ally.caseName }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + ally.name + '&color=' + color"
                >
                  {{ ally.allies.length }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + ally.name + '&color=' + color"
                >
                  {{ ally.mmr }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + ally.name + '&color=' + color"
                >
                  {{ ally.allies.length }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="country__select-container inactive">
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <h4 class="overlay_title">Aucun allié</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.allies.length }}
            </p>
          </div>
        </div>
      </div>
      <div
        @click="overlay('ennemies')"
        class="country__select-container"
        v-if="country.ennemies.length > 0"
      >
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <i class="gg-chevron-up" v-if="overlays.allies"></i>
            <i class="gg-chevron-down" v-else></i>
            <h4 class="overlay_title">Ennemis</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.ennemies.length }}
            </p>
          </div>
        </div>
        <table v-show="overlays.ennemies" class="players">
          <thead>
            <tr>
              <th>Drapeau</th>
              <th>Nom</th>
              <th>Joueurs</th>
              <th>MMR</th>
              <th>Alliés</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ennemy in country.ennemies"
              :key="ennemy.name"
              class="link"
              @click="mont(ennemy.name)"
            >
              <td>
                <router-link
                  :to="'/country?name=' + ennemy.name + '&color=' + color"
                >
                  <img :src="setFlag(ennemy.flag)" style="width: 100px" />
                </router-link>
              </td>
              <td class="important">
                <router-link
                  :to="'/country?name=' + ennemy.name + '&color=' + color"
                >
                  {{ ennemy.caseName }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + ennemy.name + '&color=' + color"
                >
                  {{ ennemy.allies.length }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + ennemy.name + '&color=' + color"
                >
                  {{ ennemy.mmr }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + ennemy.name + '&color=' + color"
                >
                  {{ ennemy.allies.length }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="country__select-container inactive">
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <h4 class="overlay_title">Aucun ennemi</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.ennemies.length }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="country__select-container"
        v-if="country.colonies.length > 0"
        @click="overlay('colonies')"
      >
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <i class="gg-chevron-up" v-if="overlays.colonies"></i>
            <i class="gg-chevron-down" v-else></i>
            <h4 class="overlay_title">Colonies</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.colonies.length }}
            </p>
          </div>
        </div>
        <table v-show="overlays.colonies">
          <thead>
            <tr>
              <th>Drapeau</th>
              <th>Nom</th>
              <th>Joueurs</th>
              <th>MMR</th>
              <th>Alliés</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colony in country.colonies"
              :key="colony.name"
              class="link"
              @click="mont(ennemy.name)"
            >
              <td>
                <router-link
                  :to="'/country?name=' + colony.name + '&color=' + color"
                >
                  <img :src="setFlag(colony.flag)" style="width: 100px" />
                </router-link>
              </td>
              <td class="important">
                <router-link
                  :to="'/country?name=' + colony.name + '&color=' + color"
                >
                  {{ colony.caseName }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + colony.name + '&color=' + color"
                >
                  {{ colony.allies.length }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + colony.name + '&color=' + color"
                >
                  {{ colony.mmr }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/country?name=' + colony.name + '&color=' + color"
                >
                  {{ colony.allies.length }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="country__select-container"
        v-if="country.assauts.all > 0"
        @click="overlay('assauts')"
      >
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <i class="gg-chevron-up" v-if="overlays.assauts"></i>
            <i class="gg-chevron-down" v-else></i>
            <h4 class="overlay_title">Assauts</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.assauts.all }}
            </p>
          </div>
        </div>
        <div>
          <p>
            <span>{{ country.assauts.win }} gagnés</span
            ><span>{{ country.assauts.loose }} perdus</span
            ><span>{{ country.assauts.equals }} égaux</span
            ><span>{{ country.assauts.all }} total</span>
          </p>
        </div>
        <table v-if="overlays.assauts">
          <tr>
            <th></th>
          </tr>
          <tr v-for="assaut in country.assauts.history" :key="assaut.name">
            {{
              assaut
            }}
          </tr>
        </table>
      </div>
      <div v-else class="country__select-container inactive">
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <h4 class="overlay_title">Aucun assaut recensé</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.assauts.all }}
            </p>
          </div>
        </div>
      </div>
      <div
        @click="overlay('notations')"
        class="country__select-container"
        v-if="country.notations.length > 0"
      >
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <i class="gg-chevron-up" v-if="overlays.notations"></i>
            <i class="gg-chevron-down" v-else></i>
            <h4 class="overlay_title">Notations</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.notations.length }}
            </p>
          </div>
        </div>
        <table v-if="overlays.notations" class="players">
          <thead>
            <tr>
              <th>Date</th>
              <th>A</th>
              <th>G</th>
              <th>E</th>
              <th>S</th>
              <th>M</th>
              <th>A</th>
              <th>Red</th>
              <th>U</th>
              <th>Arch</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notation in country.notations" :key="notation.week">
              <td>{{ notation.date }}</td>
              <td>{{ notation.activity }}</td>
              <td>{{ notation.management }}</td>
              <td>{{ notation.economy }}</td>
              <td>{{ notation.skills }}</td>
              <td>{{ notation.millitary }}</td>
              <td>{{ notation.antimatter }}</td>
              <td>{{ notation.redmatter }}</td>
              <!-- <td>{{ notation.rocket }}</td> -->
              <td>{{ notation.unesco }}</td>
              <td>{{ notation.architecture }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="country__select-container inactive">
        <div class="country__select-container__header">
          <div class="country__select-container__header-left">
            <h4 class="overlay_title">Aucune notation enregistrée</h4>
          </div>
          <div class="country__select-container__header-right">
            <p class="number_box">
              {{ country.notations.length }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="dynmaps">
      <h4>Vue aérienne des installations du pays</h4>
      <p>
        Vous montre directement le pays dans chacune des dimension ou il est
        présent, aux bonnes coordonnées
      </p>
      <iframe
        v-if="country.location.earth[0]"
        :src="getCoordsUrl(country.location.earth)"
      />
      <iframe
        v-if="country.location.moon[0]"
        :src="getCoordsUrl(country.location.moon, 'DIM-28')"
      />
      <iframe
        v-if="country.location.mars[0]"
        :src="getCoordsUrl(country.location.mars, 'DIM-29')"
      />
    </div>
  </div>
</template>

<script>
import getters from "../service/getters";
import palette from "../service/palette";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "CountryPanel",
  components: { ProgressBar },
  props: {
    color: String,
    country: Object,
  },
  methods: {
    getCoordsUrl(dim, worldname) {
      if (dim && dim[0]) {
        const claims = dim[0].claims;
        const x =
            claims[0][0] -
            parseInt(claims[0][0] - claims[claims.length / 2][0]) / 2,
          y = 0,
          z =
            claims[0][1] -
            parseInt(claims[0][1] - claims[claims.length / 2][1]) / 2,
          zoom =
            6 -
            parseInt(Math.abs(claims[0][0] - claims[claims.length / 2][0])) /
              200;
        return `https://${this.color}.nationsglory.fr/?worldname=${worldname}&mapname=flat&zoom=${zoom}&x=${x}&y=${y}&z=${z}&nopanel=true&hidechat=true&nocompass=true`;
      }
    },
    setFlag(flag) {
      return flag
        ? flag.includes("https")
          ? flag
          : "data:image/png;base64," + flag
        : "https://i.imgur.com/ZB0yLME.png";
    },
    overlay(type) {
      this.overlays[type] = !this.overlays[type];
    },
    countryUrl(country) {
      return "/country?name=" + country.name + "&color=" + this.color;
    },
    sinceTs(ts) {
      console.log(this.color);
      return getters.sinceTimeStamp(ts);
    },
    styleOfGrade(grade) {
      const rgb = palette.grade(grade);
      return rgb
        ? {
            color: "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ")",
          }
        : {};
    },
  },
  data: () => {
    return {
      country: null,
      name: "",
      overlays: {
        players: false,
        allies: false,
        ennemies: false,
        colonies: false,
        notations: false,
        assauts: false,
      },
    };
  },
};
</script>


<style scoped>
.country {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.country__infos {
  width: 70%;
}

.country__infos__head {
  display: flex;
}

.country__infos__head .country__infos__head__flag {
  margin-right: 20px;
}

.country__infos__head-titles {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.country__infos__head-titles:nth-child(2) {
  color: var(--paragraph);
}

.country__infos__head__flag-img {
  border-radius: 5px;
}

.country__infos_statistics {
  display: flex;
  color: var(--headline);
}

.country__infos_statistics {
  margin-top: 2em;
  margin-bottom: 2em;

  display: flex;
  justify-content: space-evenly;
}

.country__infos_statistics__container-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;

  color: var(--paragraph);
}

.country__infos_statistics__container-value {
  text-align: center;
  color: var(--headline);

  font-weight: 800;

  margin-bottom: 0.3em;
}

.country__select-container {
  background-color: var(--footer-color);

  border-radius: 8px;
  border: var(--paragraph) solid 1px;

  margin: 2em 0;
  padding: 2em;
  cursor: pointer;
}

.country__select-container.inactive {
  filter: brightness(80%);
}

.inactive > .country__select-container__header {
  cursor: initial;
}

.country__select-container__header {
  display: flex;
}

.country__select-container__header-left {
  display: flex;
  align-items: center;

  height: max-content;
}

.country__select-container__header-right {
  height: max-content;
  margin-left: auto;
}

.number_box {
  margin: 0;
  padding: 0.2em 0.5em;
  background-color: var(--bg-color);
  border-radius: 8px;
}

.overlay_title {
  margin: 0 0 0 10px;
  font-size: 1.3em;
}

.players {
  width: 95%;

  margin: 2em auto 0;

  text-align: left;
  vertical-align: center;

  border-spacing: 5px 1rem;
  border-collapse: collapse;
}

.players thead {
  background-color: var(--bg-color-bright);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.players th {
  padding: 15px 15px 15px 8px;
  font-size: 0.835rem;
}

.players td {
  padding: 8px;
}

.players tbody tr:nth-child(even) {
  background-color: var(--footer-color-bright);
}

#country_infos {
  margin-right: 15px;
}

h1 {
  color: var(--headline);
}

.select {
  border: 1px var(--headline) ridge;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 2px;
  transition: all 300ms ease-in-out;
}

.select .overlay_btn {
  cursor: pointer;
  padding: 7px;
  transition: all 300ms ease-in-out;
}

.select .overlay_btn:hover {
  text-decoration: underline;
  box-shadow: 1px 1px 5px;
  background: var(--footer-color);
}

.select .overlay_btn button {
  background: none;
  color: var(--headline);
  font-size: 1em;
  padding: none;
  border: none;
}

.select .overlay_btn h4 {
  font-size: 1.5em;
}

.select .overlay_btn .overlay_title {
  color: var(--headline);
  margin-left: 10px;
}

iframe {
  width: 100%;
  height: 500px;
}

.important {
  color: var(--headline);
  font-weight: bold;
  font-size: 1.2em;
}

.link {
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.link a {
  display: block;
}

/* .link:hover {
  background-color: var(--footer-color);
  transform: translateX(5px);
  box-shadow: 3px 4px 10px;
} */

#attente h4 {
  font-size: 3em;
  text-align: center;
}

#dynmaps h4 {
  font-size: 2em;
  color: var(--headline);
}

a {
  cursor: pointer;
  color: white;
  display: block;
}

/* .gg icons */
.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}
</style>