<template>
  <div class="commands" v-for="cmd in cmds" :key="cmd.description">
    <div class="command" :v-if="subCmds[prefix + cmd.name]">
      <div class="command_title">
        <h3>
          {{ prefix }}{{ cmd.name }}
          <span
              class="subCmdName"
              v-if="
              cmd.options &&
              cmd.options[0] &&
              (cmd.options[0].type === 1 || cmd.options[0].type === 2)
            "
          >
            <span
                v-for="sub in cmd.options"
                :key="sub.name"
                @click="clickSubCmd(prefix + cmd.name + sub.name)"
            >{{ sub.name }}</span
            >
          </span>
        </h3>
      </div>
      <div class="restrictions">
        <Popup v-if="cmd.defaultMemberPermissions" text="Permission administrateur" color="green" icon="admin"></Popup>
        <Popup v-if="cmd.defaultMemberPermissions" text="Indisponible en message privé" color="red" icon="mp"></Popup>
      </div>
    </div>
    <div class="box">
      <p class="description">{{ cmd.description }}</p>
      <div :class="!cmd.type ? 'commands_embed' : ''" :has-padding="!cmd.type">
        <div class="options">
          <ul class="options_items">
            <Cmd
                v-if="
                cmd.options &&
                cmd.options[0] &&
                (cmd.options[0].type === 1 || cmd.options[0].type === 2)"
                :cmds="cmd.options"
                :prefix="prefix + cmd.name + ' '"
            />

            <li
                v-else
                v-for="option in cmd.options"
                :key="option.name"
                @click="clickChoice(cmd.name + option.name)"
            >
              <div :ischoice="!!option.choices">
                <span class="option_name">
                  <span
                      v-if="option.choices && choices[cmd.name + option.name]"
                      class="choices_button"
                  >&#60;
                  </span>
                  <span v-else-if="option.choices" class="choices_button"
                  >&#62;</span
                  >{{ option.name }}
                </span>
                -
                <span class="option_description"
                >{{ option.description }}
                </span>
                <span v-if="option.required" class="required mp"
                >Obligatoire</span
                >
                <ul
                    :id="cmd.name + option.name"
                    class="mt-2"
                    v-if="option.choices"
                    :hidden="!choices[cmd.name + option.name]"
                >
                  <li v-for="choix in option.choices" :key="choix.name">
                    {{ choix.name }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
import getters from "@/service/getters";

export default {
  components: { Popup },
  props: {
    cmds: Array,
    prefix: String,
  },
  data: () => {
    return {
      choices: {},
      loading: true,
      subCmds: {},
    };
  },
  async mounted() {
    console.log(this.cmds);
  },
  events: {
    // updateCmds(data) {
    //   console.log("UPDATE PASSED");
    //   this.cmds = data;
    // },
  },
  methods: {
    clickChoice(choice) {
      this.choices[choice] = !this.choices[choice];
    },
    clickSubCmd(key) {
      this.subCmds[key] = !this.subCmds[key];
    },
  },
};
</script>


<style scoped>
.commands {
  /*padding-left: 10px;
  border-left: 2px #ffffff solid;*/
  border-radius: 4px;
  color: white;
  font-family: "gravity";
}

.command {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.restrictions {
  height: max-content;
}

.command_title {
  display: flex;
}

.command_title span {
  margin-left: 1em;
}

.subCmdName span {
  color: var(--paragraph);
}

.subCmdName span:hover {
  color: var(--primary);
}

.commands_embed {
  /* margin: 10px; */
  flex-direction: column-reverse;
  /* padding-bottom: 20px; */
  /* background-color: #292929; */
}

.commands h3 {
  font-size: 1.5em;
  font-family: "gravity";
  font-weight: 300;
}

.commands .admin {
  background-color: var(--secondary);
}

.commands .mp {
  background-color: var(--discord-background);
}

ul {
  list-style-type: none;
  border-left: 1px rgba(255, 255, 255, 0.5) solid;
  padding-left: 2em;
}

.options_items li div[ischoice="true"] {
  cursor: pointer;
}

.choices_button {
  font-size: 1.3em;
  /* color: #ff8888; */

  margin-right: 5px;
  top: 0;
}

p.description {
  font-weight: bold;
}

span.required {
  padding: 4px;
  border-radius: 5px;
  margin-left: 10px;
  color: black;
}

@media only screen and (max-width: 1100px) {
  .commands_embed {
    display: flex;
    flex-direction: column;
  }
}
</style>