<template>
  <div class="commands_container">
    <h2>Commandes :</h2>
    <div class="container">
      <Cmd :prefix="'/'" :cmds="commands" />
      <!--<Cmd v-for="cmd in commands" :first="true" :prefix="'/'" :cmds="!Array.isArray(cmd) ? [cmd] : cmd" :key="cmd.description" />-->
    </div>
  </div>
</template>

<script>
//import component
import Cmd from "../components/Cmd";
import getters from "@/service/getters";

//use components for template
export default {
  components: { Cmd },
  data: () => {
    return {
      commands: [
        {
          name: "",
          description: "Chargement des commandes...",
          options: [],
        },
      ],
      choices: {},
      loading: true,
      subCmds: {},
    };
  },
  async mounted() {
    this.commands = await getters.getCmds();
    this.loading = false;
  },
};
</script>

<style scoped>
.commands_container {
  width: 60%;
  margin: auto;
}

.commands_container h2 {
  font-size: 3em;
  color: white;
  text-align: center;
}

.container {
  padding: 20px;
}


@media only screen and (max-width: 1100px) {
  .commands_container {
    width: 90%;
  }
}
</style>